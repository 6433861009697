import api from './api';
import { processAxiosError } from 'src/utils';

export class ProfileService {
  updatePassword = ({ oldPassword, password }) => {
    return api
      .post('/admin/me/update-password', { password, oldPassword })
      .then((res) => res.data)
      .catch(processAxiosError);
  };

  get = () => {
    return api.get('/admin/me').then((res) => res.data);
  };
}

export const profileService = new ProfileService();
