import { MONTHS } from '../constants/custom';

const getAuthTokenName = () => {
  return 'TREFLAADMIN_TOKENREST';
};

export const serialize = function (obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

// YYYY-mm-dd-HH-ii-ss:tz -> YYYY-mm-dd HH:ii:ss
export const transformTime = (str_time) => {
  const arr1 = str_time.split(':');
  const date_arr = arr1[0].split('-');
  const dt = new Date(
    Number(date_arr[0]),
    Number(date_arr[1]) - 1,
    Number(date_arr[2]),
    Number(date_arr[3]),
    Number(date_arr[4]),
    Number(date_arr[5])
  );

  const my_timezone = -dt.getTimezoneOffset();
  const time = dt.getTime();

  const timezoneOffset = Number(arr1[1]);

  const final_time = time - (my_timezone - timezoneOffset) * 60 * 1000;

  const dt_final = new Date(final_time);
  return `${str_time.substring(0, 10)} ${dt_final.toLocaleTimeString()}`;
};

// Date() -> Y-m-d-H-i-s:tz
export const convertTimeToString = (dt) => {
  if (!dt) {
    dt = new Date();
  }

  const y = dt.getFullYear();
  const m = formatTwoDigits(dt.getMonth() + 1);
  const d = formatTwoDigits(dt.getDate());
  const H = formatTwoDigits(dt.getHours());
  const i = formatTwoDigits(dt.getMinutes());
  const s = formatTwoDigits(dt.getSeconds());
  const tz = -dt.getTimezoneOffset();
  return `${y}-${m}-${d}-${H}-${i}-${s}:${tz}`;
};

export const formatToLocalTime = (dt, format) => {
  if (!dt) return '';
  // if (!dt) dt = new Date();
  if (typeof dt === 'string') dt = new Date(dt);
  const timezoneOffset = dt.getTimezoneOffset();
  dt.setMinutes(dt.getMinutes() - timezoneOffset);
  return formatTime(dt, format);
};

export const formatTime = (dt, format) => {
  if (!dt) {
    dt = new Date();
  }

  const y = dt.getFullYear();
  const m = formatTwoDigits(dt.getMonth() + 1);
  const d = formatTwoDigits(dt.getDate());
  const H = formatTwoDigits(dt.getHours());
  const i = formatTwoDigits(dt.getMinutes());
  const s = formatTwoDigits(dt.getSeconds());
  // const tz = -dt.getTimezoneOffset();

  let formatted = format;
  formatted = formatted.replace(/Y/g, y);
  formatted = formatted.replace('m', m);
  formatted = formatted.replace('d', d);
  formatted = formatted.replace('H', H);
  formatted = formatted.replace('i', i);
  formatted = formatted.replace('s', s);
  return formatted;
};

export const getAuthToken = () => {
  return '';
};

export const deleteAuthToken = () => {
  // console.log('[Auth Util] delete');
  localStorage.removeItem(getAuthTokenName());
};

export const formatTwoDigits = (num) => {
  return num > 9 ? num : `0${num}`;
};

export const getJSON = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';

    xhr.onload = function () {
      const { status } = xhr;

      if (status === 200) {
        resolve(xhr.response);
        // callback(null, xhr.response);
      } else {
        // callback(status);
        reject(false);
      }
    };

    xhr.onerror = function (err) {
      console.error(err);
      reject(false);
    };

    xhr.send();
  });
};

export const getMapPositionFromString = (str) => {
  if (str) {
    const tArray = str.split(',');
    if (tArray.length === 2) {
      return {
        lat: Number(tArray[0]),
        lng: Number(tArray[1]),
      };
    }
    return { lat: 0, lng: 0 };
  }
  return { lat: 0, lng: 0 };
};

export const moderateString = (str, len) => {
  return str.length > len ? `${str.substr(0, len)}...` : str;
};

export const toCamelCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const menuPermission = ({ role, permission }, strKey = '') => {
  if (role === 'SUPER_ADMIN') return true;
  if (!permission) return false;
  const keys = strKey.split('.');
  if (keys.length === 1) {
    return permission[keys[0]];
  } else if (keys.length === 2) {
    if (!permission[keys[0]]) return false;
    return permission[keys[0]][keys[1]];
  } else if (keys.length === 3) {
    if (!permission[keys[0]]) return false;
    return permission[keys[0]][keys[1]][keys[2]];
  } else if (keys.length === 4) {
    if (!permission[keys[0]]) return false;
    return permission[keys[0]][keys[1]][keys[2]][keys[3]];
  }
  return false;
};

export const recent7Days = () => {
  const time = new Date().getTime();

  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(getSimpleFormatDay(time - (6 - i) * 86400 * 1000));
  }

  return days;
};

export const getSimpleFormatDay = (time = null) => {
  if (!time) {
    time = new Date().getTime();
  }

  const dt = new Date(time);

  const m = dt.getMonth();
  const d = dt.getDate();
  // const d2 = formatTwoDigits(d);

  return `${MONTHS[m]} ${d}`;
};

export const statIn7Days = (data, time_key) => {
  const stat = [0, 0, 0, 0, 0, 0, 0];

  const current_time = new Date().getTime();

  // for (let i = 6; i >= 0; i++) {
  for (const item of data) {
    const str_time = transformTime(item[time_key]);
    const timeDiff = current_time - new Date(str_time).getTime();
    const dayDiff = Math.floor(timeDiff / 1000 / 3600 / 24);
    if (dayDiff < 7) {
      stat[6 - dayDiff]++;
    }
  }
  // }

  return stat;
};

export const getTimestamp = (value) => {
  let dt = new Date();
  if (typeof value === 'string') dt = new Date(value);
  if (value instanceof Date) dt = value;
  return Math.floor(dt.getTime() / 1000);
};

export const formatMacAddress = (mac) => {
  const findAllComma = new RegExp(':', 'g');
  let macWithoutComma = mac.replace(findAllComma, '');
  const inputFully = macWithoutComma.length === 12;
  let newMac = '';
  while (macWithoutComma.length > 0) {
    newMac += macWithoutComma.substring(0, 2) + ':';
    macWithoutComma = macWithoutComma.substring(2);
  }
  const isLastPartOne =
    newMac[newMac.length - 3] === ':' || newMac.length === 2;

  return newMac.substring(
    0,
    newMac.length - (inputFully || isLastPartOne ? 1 : 0)
  );
};

export const sleep = (ms) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

export const plus = (a, b) => Number(a) + Number(b);

export const validateUserNeedActivate = (isPaid, strExpireAt) => {
  if (!isPaid) return true;
  const expireYear = new Date(strExpireAt).getFullYear();
  const currentYear = new Date().getFullYear();
  return expireYear < currentYear + 2;
};

export const processAxiosError = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    throw new Error(error.response.data.message);
  }
  throw new Error(error.message);
};
